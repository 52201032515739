<template>
  <h1>Impressum</h1>

  <div class="Table">
    <div>Herausgeber</div>
    <div>
      peter milde + partner<br />
      strategy consulting<br />
      Unterau 14<br />
      D-82444 Schlehdorf am Kochelsee / Oberbayern
    </div>
    <div>Telefon</div>
    <div>+ 49 (0) 8851 / 92 55 - 0</div>
    <div>Fax</div>
    <div>+ 49 (0) 8851 / 92 55 – 44</div>
    <div>eMail:</div>
    <div>mail@pmp-sc.com</div>
    <div>Homepage:</div>
    <div>https://www.pmp-sc.com</div>
  </div>
  <br />
  <div class="Table">
    <div>Rechtsform:</div>
    <div>Gesellschaft des bürgerlichen Rechts (GbR)</div>
    <div>Steuernummer:</div>
    <div>USt-IDNr.: DE 230 55 9006</div>
    <div>Inhaltliche Verantwortung:</div>
    <div>Dipl.-Kfm. Peter Milde</div>
  </div>
  <h2>Dienstanbieter gemäß Telemediengesetz:</h2>
  <div>
    http://www.web-vision.de<br />
    Email: contact@web-vision.de <br />
    Telefon: +49 (0) 21 66 - 94 04 54 <br />
    Ansprechpartner: Boris Hinzer
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Impressum',
  components: {},
})
</script>

<style scoped lang="scss">
.home {
  margin: 0px auto;
  background-color: white;
}
.Table {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-gap: 10px;
}
</style>
